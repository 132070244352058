import { useRouter } from 'next/router';
import { NextPageFC, Nullable } from 'types';
import SearchForm from '@lib/Components/SearchForm';
import { NewsList } from '@components/NewsList';
import { Text } from '@theme/shared/text';
import { useNewsCategories, useNewsListApi } from '@http/hooks/news';
import { LoaderBox } from '@components/LoaderBox';
import { Button } from '@components/Button';
import { newsParamsFactory, routes } from 'routes';
import { ErrorContainer } from '@components/ErrorContainer/ErrorContainer';
import { SearchNotFound } from '@components/SearchNotFound';
import { MarginWrapper } from '@theme/shared/wrappers';
import { NewsTags } from '@components/NewsTags/NewsTags';
import { getPaginationNews, LIMIT_NEWS_ARTICLES } from '@http/shared';
import { DivWrapper } from '@theme/shared/wrappers';
import { Icon } from '@components/Icon';
import { NewsTagsLoader } from '@components/NewsTagsLoader/NewsTagsLoader';
import { useTariffGuard } from 'providers/tariff-guard';

const paginate = getPaginationNews({
  skip: 1,
  limit: LIMIT_NEWS_ARTICLES,
  isPublished: true,
});

const HomeContent: NextPageFC = () => {
  const { data, isLoading, isError } = useNewsListApi(paginate);
  const { data: categories, isLoading: isCategoriesLoading } = useNewsCategories();
  const router = useRouter();
  const { recods: newsList = [] } = data || {};
  const { isRouteForbidden, setModalOpen, filterNewsByCatId } = useTariffGuard();

  const handleSelect = (categoryId: Nullable<number>) => {
    const route = routes.newsList(newsParamsFactory({ categoryId }));
    const isForbidden = isRouteForbidden(route);
    if (isForbidden) {
      setModalOpen(true);
      return;
    }

    router.push(route);
  };

  if (isLoading) return <LoaderBox />;

  if (isError) return <ErrorContainer />;

  if (newsList && newsList.length === 0) return <SearchNotFound title="К сожалению, пока новостей нет" />;

  const filteredNews = filterNewsByCatId(newsList);

  return (
    <>
      <MarginWrapper marginBottom="l">
        {isCategoriesLoading && <NewsTagsLoader />}
        {!isCategoriesLoading && categories && categories.length > 0 && (
          <NewsTags categories={categories} selectedCategory={null} onSelect={handleSelect} />
        )}
      </MarginWrapper>
      <NewsList newsList={filteredNews} />
      <DivWrapper width="200px" margin="0 auto">
        <Button startIcon={<Icon color="main.light" name="plus-circle.filled" />} href={routes.newsList()} fullWidth>
          Больше новостей
        </Button>
      </DivWrapper>
    </>
  );
};

const Home: NextPageFC = () => {
  return (
    <>
      <SearchForm onSubmit={console.log} />
      <DivWrapper marginTop="s">
        <Text.Header3>Последние новости</Text.Header3>
      </DivWrapper>
      <HomeContent />
    </>
  );
};

export default Home;
