import { dehydrate, QueryClient } from 'react-query';
import { GetServerSideProps } from 'next';
import { MainLayout } from '@components/main-layout';
import { getNewsCategories, getNewsListApi } from '@http/endpoints/news';
import Home from 'screens/Home';
import { getPaginationNews, LIMIT_NEWS_ARTICLES } from '@http/shared';

Home.layout = ({ children }) => MainLayout({ children, topMenuProps: { title: 'Поиск утилизаторов' } });
Home.headProps = {
  title: 'Kritod.ru - поиск утилизаторов',
  description: 'Поиск утилизаторов и экологов на единой платформе',
};

const paginate = getPaginationNews({
  skip: 1,
  limit: LIMIT_NEWS_ARTICLES,
  isPublished: true,
});

export const getServerSideProps: GetServerSideProps = async () => {
  const queryClient = new QueryClient();
  await queryClient.prefetchQuery(['news-list-api', paginate], () => getNewsListApi(paginate));
  await queryClient.prefetchQuery(['news-category'], () => getNewsCategories());

  return {
    props: {
      dehydratedState: dehydrate(queryClient),
    },
  };
};

export default Home;
