import { Skeleton } from '@material-ui/lab';
import React, { FC } from 'react';
import { DivWrapper } from '@theme/shared/wrappers';

const items = [1, 2, 3];

export const NewsTagsLoader: FC = () => {
  return (
    <DivWrapper display="flex" gap="xxs" alignItems="center" justifyContent="flex-start">
      {items.map(n => (
        <Skeleton key={n} height={60} width={100} />
      ))}
    </DivWrapper>
  );
};
